import 'Shared/sass/widgets/WidgetContainer.scss'

import { keyframes } from '@emotion/react'
import React, { FunctionComponent } from 'react'
import Reveal from 'react-awesome-reveal'

// import Slide from 'react-awesome-reveal'
import PuxAnchor from '../PuxAnchor/PuxAnchor'
import {
  WidgetAnimationPart,
  WidgetMetadata,
  WidgetProperitesPart,
} from './WidgetBuilder'

interface WidgetContainerProps
  extends WidgetProperitesPart,
  WidgetAnimationPart,
  WidgetMetadata {
  children: React.ReactNode
  fullWidth?: boolean
  thin?: boolean
  cropped?: boolean
}

interface WidgetAttributes {
  className: string
  id?: string
}

const rootClass = `widget-container`

const getAnimation = (animationType: string) => {
  switch (animationType) {
    case `fade-in`:
      return keyframes`
        from {
          opacity: 0;
        }
        
        to {
          opacity: 1;
        }
      `

    case `fade-from-top`:
      return keyframes`
        from {
          opacity: 0;
          transform: translate(0, -200px);
        }
        
        to {
          opacity: 1;
          transform: translate(0, 0);
        }
      `

    case `fade-from-left`:
      return keyframes`
        from {
          opacity: 0;
          transform: translate(-200px, 0);
        }
        
        to {
          opacity: 1;
          transform: translate(0, 0);
        }
      `

    case `fade-from-right`:
      return keyframes`
        from {
          opacity: 0;
          transform: translate(200px, 0);
        }
        
        to {
          opacity: 1;
          transform: translate(0, 0);
        }
      `

    default:
      return null
  }
}

const WidgetContainer: FunctionComponent<WidgetContainerProps> = (props) => {
  let className = rootClass

  className += props.fullWidth ? `` : ` ${rootClass}-padding`

  className += props.thin ? ` ${rootClass}-thin` : ``

  className += props.cropped ? ` ${rootClass}-cropped` : ``

  props.widgetPropertiesPartMarginBottom
    ? (className += ` ${props.widgetPropertiesPartMarginBottom}`)
    : null
  props.widgetPropertiesPartMarginTop
    ? (className += ` ${props.widgetPropertiesPartMarginTop}`)
    : null
  props.widgetPropertiesPartPaddingBottom
    ? (className += ` ${props.widgetPropertiesPartPaddingBottom}`)
    : null
  props.widgetPropertiesPartPaddingTop
    ? (className += ` ${props.widgetPropertiesPartPaddingTop}`)
    : null
  props.widgetPropertiesPartMobileOrder
    ? (className += ` mobile-order-${props.widgetPropertiesPartMobileOrder}`)
    : null
  props.size ? (className += ` w-${props.size}`) : null
  props.alignment ? (className += ` ${props.alignment}`) : null

  const widgetAttributes: WidgetAttributes = {
    className: className,
  }

  const widgetAnimation = getAnimation(props.widgetAnimationPartType)

  // props.widgetPropertiesPartID
  //   ? (widgetAttributes.id = props.widgetPropertiesPartID)
  //   : null

  const getAnimationWrapper = (widgetChildren) => {
    if (props.widgetAnimationPartIsEnabled && widgetAnimation) {
      return (
        <Reveal
          keyframes={widgetAnimation}
          triggerOnce
          fraction={0}
          duration={500}
          delay={150}
          style={{
            transitionTimingFunction: `cubic-bezier(.25,.46,.45,.94)`,
            animationFillMode: `backwards`,
          }}
        >
          {widgetChildren}
        </Reveal>
      )
    } else {
      return widgetChildren
    }
  }

  if (!props.widgetPropertiesPartID) {
    return (
      <div {...widgetAttributes}>{getAnimationWrapper(props.children)}</div>
    )
  } else if (props.widgetPropertiesPartID === `remove-position-relative`) {
    return (
      <div {...widgetAttributes} id={props.widgetPropertiesPartID}>
        {getAnimationWrapper(props.children)}
      </div>
    )
  } else {
    return (
      <div {...widgetAttributes}>
        <PuxAnchor id={props.widgetPropertiesPartID} />
        {getAnimationWrapper(props.children)}
      </div>
    )
  }
}

export default WidgetContainer

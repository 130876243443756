import 'Shared/sass/widgets/WidgetPuxSection.scss'

import React, { FunctionComponent } from 'react'
import WidgetBuilder, {
  WidgetData,
  WidgetProperitesPart,
} from 'Shared/components/builders/WidgetBuilder'
import PuxAnchor from 'Shared/components/PuxAnchor/PuxAnchor'
import PuxModal from 'Shared/components/PuxModal/PuxModal'

export interface WidgetPuxSectionType {
  contentType: 'PuxSection'
  contentItemId?: string
  sectionUseContainer: boolean
  sectionBordersBetweenWidgets: boolean
  sectionIsModal?: boolean
  sectionModalId?: string
  noSpacing: boolean
  template: [
    {
      contentItemId: string
    }
  ]
  puxBackgroundDefinition: {
    backgroundDefinitionPartColor: string
  }
  flow: {
    widgets: [WidgetData]
  }
  puxWidgetProperties: WidgetProperitesPart
}

const WidgetPuxSection: FunctionComponent<WidgetPuxSectionType> = (props) => {
  interface WidgetAttributes {
    className: string
    id?: string
  }

  const { template } = props

  if (props.template?.length > 1) {
    props.flow.widgets.sort((widget1, widget2) => {
      const index1 = template.findIndex(
        (x) => x.contentItemId === widget1.contentItemId
      )
      const index2 = template.findIndex(
        (x) => x.contentItemId === widget2.contentItemId
      )
      return index1 - index2
    })
  }

  const widgetProperties = props.puxWidgetProperties
  let className = `widget-section`
  props.puxBackgroundDefinition?.backgroundDefinitionPartColor
    ? (className += ` ${props.puxBackgroundDefinition.backgroundDefinitionPartColor}`)
    : null
  widgetProperties.widgetPropertiesPartMarginBottom
    ? (className += ` ${widgetProperties.widgetPropertiesPartMarginBottom}`)
    : null
  widgetProperties.widgetPropertiesPartMarginTop
    ? (className += ` ${widgetProperties.widgetPropertiesPartMarginTop}`)
    : null
  widgetProperties.widgetPropertiesPartPaddingBottom
    ? (className += ` ${widgetProperties.widgetPropertiesPartPaddingBottom}`)
    : null
  widgetProperties.widgetPropertiesPartPaddingTop
    ? (className += ` ${widgetProperties.widgetPropertiesPartPaddingTop}`)
    : null
  props.sectionBordersBetweenWidgets
    ? (className += ` borders-between-widgets`)
    : null

  const widgetAttributes: WidgetAttributes = {
    className: className,
  }

  const content = (
    <section {...widgetAttributes}>
      <div
        className={
          `pux-container` +
          `${props.sectionUseContainer ? `` : ` pux-container-fluid`}`
        }
      >
        <div className='widget-section-grid'>
          <WidgetBuilder widgetBuilderData={props.flow.widgets} />
        </div>
      </div>
    </section>
  )

  if (
    props.sectionIsModal &&
    typeof props.sectionModalId === `string` &&
    props.sectionModalId.length > 0
  ) {
    return <PuxModal id={props.sectionModalId}>{content}</PuxModal>
  } else if (widgetProperties.widgetPropertiesPartID) {
    return (
      <PuxAnchor id={widgetProperties.widgetPropertiesPartID}>
        {content}
      </PuxAnchor>
    )
  } else {
    return content
  }
}

export default WidgetPuxSection
